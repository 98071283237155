import React from "react";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
    return (
        <Box
            bg="rgba(0, 0, 0, 0.5)" // Transparent black background
            w="100%"
            p={4}
            color="white"
            mt={4} // Add margin from the top
        >
            <Flex justifyContent="center" alignItems="center">
                {/* Facebook Icon */}
                <a href="https://www.facebook.com/profile.php?id=100086507393639&mibextid=eHce3h">
                    <IconButton
                        aria-label="Facebook"
                        icon={<FaFacebook />}
                        colorScheme="whiteAlpha"
                        variant="ghost"
                        fontSize= "xx-large" // Increased icon size
                        mr={4} // Margin right for spacing
                    />
                </a>
                {/* Instagram Icon */}
                <a href="https://www.instagram.com/studiofeya83">
                    <IconButton
                        aria-label="Instagram"
                        icon={<FaInstagram />}
                        colorScheme="whiteAlpha"
                        variant="ghost"
                        fontSize= "xx-large" // Increased icon size
                    />
                </a>
            </Flex>
            {/* Copyright and photo attribution */}
            <Text textAlign="center" mt={4}>
                Copyright © 2024 alfia studio
                <br />
                Photos by forteen
            </Text>
        </Box>
    );
};

export default Footer;
