import React, { useState, useEffect, useCallback } from "react";
import { Container, SimpleGrid, Text } from "@chakra-ui/react";
import ProductCard from "./ProductCard";
import InfiniteScroll from "react-infinite-scroll-component";

const PAGE_SIZE = 5;
const PICTURE_AMOUNT = 38;

const generatePhotoUrls = async (start: number, end: number, setHasMore: (value: boolean) => void): Promise<string[]> => {
  const newPhotos: string[] = [];
  let p = 0;
  for (let i = start; i < end; i++) {
    const imageUrl = `./products/${i}.jpg`;
    p = i;
    try {
      const response = await fetch(imageUrl);
      if (response.ok) {
        if (!(i > PICTURE_AMOUNT)) {
          newPhotos.push(imageUrl);
        }
      } else {
        console.error(`Image ${imageUrl} not found.`);
      }
    } catch (error) {
      console.error(`Error fetching image ${imageUrl}:`, error);
    }
  }

  if (p >= PICTURE_AMOUNT) {
    setHasMore(false);
  }
  return newPhotos;
};

const getPhotoInfo = async (photos: string[], setTitles: React.Dispatch<React.SetStateAction<string[]>>, setPrices: React.Dispatch<React.SetStateAction<string[]>>) => {
  try {
    const response = await fetch("./description&price.txt");
    const text = await response.text();
    const lines = text.split('\n').map(line => line.trim());

    const newTitles: string[] = [];
    const newPrices: string[] = [];

    photos.forEach((photo, index) => {
      // Extract image number from photo URL
      const matchResult = photo.match(/\d+/);
      const photoNumber = matchResult ? parseInt(matchResult[0]) : -1; // Default value if matchResult is null
      console.log("Photo Number:", photoNumber); // Debugging
      newTitles.push(lines[photoNumber * 2] || "Unknown Title");
      newPrices.push(lines[photoNumber * 2 + 1] || "Unknown Price");
    });

    setTitles(prevTitles => [...prevTitles, ...newTitles]);
    setPrices(prevPrices => [...prevPrices, ...newPrices]);
  } catch (error) {
    console.error("Error fetching description & price:", error);
  }
}

function HomePage() {
  const [photos, setPhotos] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [titles, setTitles] = useState<string[]>(Array.from({ length: PAGE_SIZE }, () => ""));
  const [prices, setPrices] = useState<string[]>(Array.from({ length: PAGE_SIZE }, () => ""));
  const [firstFetch, setFirstFetch] = useState<boolean>(true);

  const fetchPhotos = useCallback(async () => {
    const start = (page - 1) * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    const newPhotos = await generatePhotoUrls(start, end, setHasMore);

    if (newPhotos.length === 0) {
      return;
    }

    if (firstFetch) {
      setPhotos(newPhotos);
      setFirstFetch(false);
    } else {
      setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos.filter(photo => !prevPhotos.includes(photo))]);
    }

    setPage((prevPage) => prevPage + 1);
    getPhotoInfo(newPhotos, setTitles, setPrices);
  }, [page, firstFetch]);

  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos]);

  return (
    <Container maxW="6xl" padding={5}>
      <InfiniteScroll
        dataLength={photos.length}
        next={fetchPhotos}
        hasMore={hasMore}
        loader={<Text>Loading...</Text>}
      >
        <SimpleGrid columns={{ sm: 2, md: 5 }} gap={2}>
        {photos.slice(10).map((photo, index) => {
  const title = titles[index + 10];
  const price = prices[index + 10];
  return <ProductCard key={index + 10} src={photo} title={title} price={price} />;
})}
        </SimpleGrid>
      </InfiniteScroll>
    </Container>
  );
}

export default HomePage;
