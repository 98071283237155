import React from "react";
import HomePage from "./components/HomePage";
import Header from './Header';
import Footer from './Footer'; // Import Footer component
import { ChakraProvider, Box } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import bgImage from './images/bg.jpg';

const client = new QueryClient();

function App() {
  return (
    <ChakraProvider>
      <Box
        w="100%"
        h="100vh"
        position="fixed"
        top="0"
        left="0"
        backgroundImage={`url(${bgImage})`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        zIndex="-1" // Ensure the background image stays behind other content
      />
      <div className="App">
        <Header />
        <QueryClientProvider client={client}>
          <HomePage />
        </QueryClientProvider>
        {/* Include Footer component */}
        <Footer />
      </div>
    </ChakraProvider>
  );
}

export default App;
