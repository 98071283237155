import React from "react";
import { AspectRatio, Card, CardBody, Heading, Image, Stack, Text } from "@chakra-ui/react";

interface Props {
  src: string;
  price: string;
  title: string;
}

const ProductCard: React.FC<Props> = ({ src, price, title }) => {
  return (
    <Card>
      <CardBody>
        <AspectRatio ratio={1}>
          <Image
            src={src}
            rounded="lg"
          />
        </AspectRatio>
        <Stack mt={5}>
          <Heading size="md">{title}</Heading>
          <Text>{price}</Text>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ProductCard;
