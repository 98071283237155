import React from "react"; // Importing React library
import ReactDOM from "react-dom/client"; // Importing ReactDOM for rendering React components
import App from "./App"; // Importing the root component of the application

// Creating a root ReactDOM instance to render the application into the DOM
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement // Getting the root DOM element by its id
);

// Rendering the root component (App) wrapped in React.StrictMode
root.render(
  <React.StrictMode>
    <App /> {/* The root component of the application */}
  </React.StrictMode>
);
